/* App.css */
header {
  background-color: #fff;
  padding: 10px;
  text-align: center;
  border-bottom: gray;
  border-width: 10px;
}

body {
  background-color: #fefaf4;
}

header img {
  width: 120px;
  height: auto;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.seperator {
  height: 30px;
}

input {
  width: 220px;
  background-color: #fff;
  border: none;
  padding: 10px;
  height: 30px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 16px;
}
