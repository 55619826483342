/* Button.css */
.button {
  background-color: #70a376;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 250px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #395009;
}
